@import './src/styles/variables.scss';


/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Morgen Licht';
  src: url('../fonts/morgenlicht.ttf') format('truetype');
}

p{
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 1.25rem;
}

a{
  color: $color-black !important;
  text-decoration: none !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 1.25rem;
}


h1{
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.pointer{
  cursor: pointer;
}

h1, h2, h3{
  font-family: 'Oswald', sans-serif;
  letter-spacing: 4px;
}

.reveal{
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

//Iframe youtube responsivo
.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container-vertical {
  position: relative;
  padding-top: 177.25%; /* Proporção do vídeo: 9/16 (altura/largura) */
  width: 100%;
  max-width: 800px; /* Largura máxima */
}

.video-container-vertical iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@media (max-width: 768px) {
  p{
    font-size: 1.25rem;
  }

  h1{
    font-size: 2rem;
  }
  h2{
    font-size: 1.75rem;
  }
  h3{
    font-size: 1.5rem;
  }
}

#project-detail{
  background-color: $color-black;
  padding-top: 4.6rem;

  h1, p{
    color: $color-white;
  }

  h1{
    font-family: 'Morgen Licht', sans-serif;
  }

  .close{
    letter-spacing: 4px;
    transition: all 0.5s;
  }
  .close:hover{
    transform: scale(1.1);
  }

  .banner{

    .brand{
      font-size: 1.25rem!important;
      margin-bottom: 20px;
      @media (max-width: 565px) {
        font-size: 1.5rem;
      }
    }

    .project-title{
      font-size: 2.25rem !important;
      line-height: 31px !important;

      @media (max-width: 565px) {
        font-size: 2.125rem !important;
        line-height: 30px !important;
      }
    }

    .project-description{
      font-size: 1.25rem !important;
    }
  }

}


